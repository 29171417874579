@font-face {
  font-family: "old-mushin-icons";
  src: url("/old-mushin-icons.eot?#iefix") format("embedded-opentype"),
url("/old-mushin-icons.woff") format("woff"),
url("/old-mushin-icons.woff2") format("woff2"),
url("/old-mushin-icons.ttf") format("truetype"),
url("/old-mushin-icons.svg#old-mushin-icons") format("svg");
}

.old-icon {
}

.old-icon:before {
  font-family: old-mushin-icons !important;
  font-style: normal;
  font-weight: normal !important;
  vertical-align: top;
}

.old-icon-add-folder:before {
                             content: "\f101";
                           }
.old-icon-add-in-chutier:before {
                             content: "\f102";
                           }
.old-icon-add-user-round:before {
                             content: "\f103";
                           }
.old-icon-add-user:before {
                             content: "\f104";
                           }
.old-icon-align-bottom:before {
                             content: "\f105";
                           }
.old-icon-align-left:before {
                             content: "\f106";
                           }
.old-icon-align-right:before {
                             content: "\f107";
                           }
.old-icon-align-top:before {
                             content: "\f108";
                           }
.old-icon-align-vcenter:before {
                             content: "\f109";
                           }
.old-icon-arrow-h:before {
                             content: "\f10a";
                           }
.old-icon-arrow-left:before {
                             content: "\f10b";
                           }
.old-icon-arrow-right:before {
                             content: "\f10c";
                           }
.old-icon-arrow-v:before {
                             content: "\f10d";
                           }
.old-icon-bell:before {
                             content: "\f10e";
                           }
.old-icon-bring-to-front:before {
                             content: "\f10f";
                           }
.old-icon-caret-down:before {
                             content: "\f110";
                           }
.old-icon-caret-left:before {
                             content: "\f111";
                           }
.old-icon-caret-right:before {
                             content: "\f112";
                           }
.old-icon-cart:before {
                             content: "\f113";
                           }
.old-icon-check-circle-o:before {
                             content: "\f114";
                           }
.old-icon-check:before {
                             content: "\f115";
                           }
.old-icon-checkbox-checked-plain:before {
                             content: "\f116";
                           }
.old-icon-checkbox-checked:before {
                             content: "\f117";
                           }
.old-icon-checkbox-empty:before {
                             content: "\f118";
                           }
.old-icon-checklist:before {
                             content: "\f119";
                           }
.old-icon-chutier:before {
                             content: "\f11a";
                           }
.old-icon-circle:before {
                             content: "\f11b";
                           }
.old-icon-close:before {
                             content: "\f11c";
                           }
.old-icon-Cloud:before {
                             content: "\f11d";
                           }
.old-icon-comment:before {
                             content: "\f11e";
                           }
.old-icon-comments:before {
                             content: "\f11f";
                           }
.old-icon-company:before {
                             content: "\f120";
                           }
.old-icon-compress:before {
                             content: "\f121";
                           }
.old-icon-copy:before {
                             content: "\f122";
                           }
.old-icon-cross-circle-o:before {
                             content: "\f123";
                           }
.old-icon-dessin:before {
                             content: "\f124";
                           }
.old-icon-edit-picture:before {
                             content: "\f125";
                           }
.old-icon-edit:before {
                             content: "\f126";
                           }
.old-icon-empty:before {
                             content: "\f127";
                           }
.old-icon-expand:before {
                             content: "\f128";
                           }
.old-icon-eye:before {
                             content: "\f129";
                           }
.old-icon-file-text-o:before {
                             content: "\f12a";
                           }
.old-icon-filled_star:before {
                             content: "\f12b";
                           }
.old-icon-filled-star:before {
                             content: "\f12c";
                           }
.old-icon-fit-center:before {
                             content: "\f12d";
                           }
.old-icon-fleche:before {
                             content: "\f12e";
                           }
.old-icon-folder-bold:before {
                             content: "\f12f";
                           }
.old-icon-folder-plus:before {
                             content: "\f130";
                           }
.old-icon-folder:before {
                             content: "\f131";
                           }
.old-icon-free-cursor:before {
                             content: "\f132";
                           }
.old-icon-full:before {
                             content: "\f133";
                           }
.old-icon-grid:before {
                             content: "\f134";
                           }
.old-icon-group:before {
                             content: "\f135";
                           }
.old-icon-hamburger:before {
                             content: "\f136";
                           }
.old-icon-hearts:before {
                             content: "\f137";
                           }
.old-icon-help-circle:before {
                             content: "\f138";
                           }
.old-icon-link:before {
                             content: "\f139";
                           }
.old-icon-logo-2:before {
                             content: "\f13a";
                           }
.old-icon-logo:before {
                             content: "\f13b";
                           }
.old-icon-logout:before {
                             content: "\f13c";
                           }
.old-icon-magnify-search:before {
                             content: "\f13d";
                           }
.old-icon-mail:before {
                             content: "\f13e";
                           }
.old-icon-moodboard-drag:before {
                             content: "\f13f";
                           }
.old-icon-moodboard-org:before {
                             content: "\f140";
                           }
.old-icon-moodboard-save:before {
                             content: "\f141";
                           }
.old-icon-move-folder:before {
                             content: "\f142";
                           }
.old-icon-move-to-folder:before {
                             content: "\f143";
                           }
.old-icon-mushin-logo-quadri:before {
                             content: "\f144";
                           }
.old-icon-next:before {
                             content: "\f145";
                           }
.old-icon-note-bold:before {
                             content: "\f146";
                           }
.old-icon-note:before {
                             content: "\f147";
                           }
.old-icon-paperplane:before {
                             content: "\f148";
                           }
.old-icon-password:before {
                             content: "\f149";
                           }
.old-icon-pdf:before {
                             content: "\f14a";
                           }
.old-icon-phone:before {
                             content: "\f14b";
                           }
.old-icon-picture:before {
                             content: "\f14c";
                           }
.old-icon-pinterest:before {
                             content: "\f14d";
                           }
.old-icon-plus-circle-bold:before {
                             content: "\f14e";
                           }
.old-icon-plus-circle-empty:before {
                             content: "\f14f";
                           }
.old-icon-plus-circle-filled:before {
                             content: "\f150";
                           }
.old-icon-plus-circle:before {
                             content: "\f151";
                           }
.old-icon-preview:before {
                             content: "\f152";
                           }
.old-icon-previous:before {
                             content: "\f153";
                           }
.old-icon-print:before {
                             content: "\f154";
                           }
.old-icon-rotate-left:before {
                             content: "\f155";
                           }
.old-icon-rotate-right:before {
                             content: "\f156";
                           }
.old-icon-save:before {
                             content: "\f157";
                           }
.old-icon-send-to-back:before {
                             content: "\f158";
                           }
.old-icon-setting_equalizer:before {
                             content: "\f159";
                           }
.old-icon-share:before {
                             content: "\f15a";
                           }
.old-icon-sidebar-hamburger:before {
                             content: "\f15b";
                           }
.old-icon-sort-down:before {
                             content: "\f15c";
                           }
.old-icon-spinner:before {
                             content: "\f15d";
                           }
.old-icon-star:before {
                             content: "\f15e";
                           }
.old-icon-team-user:before {
                             content: "\f15f";
                           }
.old-icon-team:before {
                             content: "\f160";
                           }
.old-icon-template_8c:before {
                             content: "\f161";
                           }
.old-icon-template-10a:before {
                             content: "\f162";
                           }
.old-icon-template-11a:before {
                             content: "\f163";
                           }
.old-icon-template-12a:before {
                             content: "\f164";
                           }
.old-icon-template-1a:before {
                             content: "\f165";
                           }
.old-icon-template-2a:before {
                             content: "\f166";
                           }
.old-icon-template-2b:before {
                             content: "\f167";
                           }
.old-icon-template-3a:before {
                             content: "\f168";
                           }
.old-icon-template-3b:before {
                             content: "\f169";
                           }
.old-icon-template-3c:before {
                             content: "\f16a";
                           }
.old-icon-template-3d:before {
                             content: "\f16b";
                           }
.old-icon-template-4a:before {
                             content: "\f16c";
                           }
.old-icon-template-4b:before {
                             content: "\f16d";
                           }
.old-icon-template-4c:before {
                             content: "\f16e";
                           }
.old-icon-template-5a:before {
                             content: "\f16f";
                           }
.old-icon-template-5b:before {
                             content: "\f170";
                           }
.old-icon-template-5c:before {
                             content: "\f171";
                           }
.old-icon-template-5v:before {
                             content: "\f172";
                           }
.old-icon-template-6a:before {
                             content: "\f173";
                           }
.old-icon-template-6b:before {
                             content: "\f174";
                           }
.old-icon-template-7a:before {
                             content: "\f175";
                           }
.old-icon-template-7b:before {
                             content: "\f176";
                           }
.old-icon-template-8a:before {
                             content: "\f177";
                           }
.old-icon-template-8b:before {
                             content: "\f178";
                           }
.old-icon-template-8c:before {
                             content: "\f179";
                           }
.old-icon-template-9a:before {
                             content: "\f17a";
                           }
.old-icon-template-center-crop:before {
                             content: "\f17b";
                           }
.old-icon-template-center-fit:before {
                             content: "\f17c";
                           }
.old-icon-template-crop:before {
                             content: "\f17d";
                           }
.old-icon-template-split-horizontally:before {
                             content: "\f17e";
                           }
.old-icon-template-split-vertically:before {
                             content: "\f17f";
                           }
.old-icon-text-align-center:before {
                             content: "\f180";
                           }
.old-icon-text-align-justify:before {
                             content: "\f181";
                           }
.old-icon-text-align-left:before {
                             content: "\f182";
                           }
.old-icon-text-align-right:before {
                             content: "\f183";
                           }
.old-icon-text-edit:before {
                             content: "\f184";
                           }
.old-icon-tie:before {
                             content: "\f185";
                           }
.old-icon-topic:before {
                             content: "\f186";
                           }
.old-icon-trash:before {
                             content: "\f187";
                           }
.old-icon-upload-cloud:before {
                             content: "\f188";
                           }
.old-icon-user-login:before {
                             content: "\f189";
                           }
.old-icon-user:before {
                             content: "\f18a";
                           }
.old-icon-warning:before {
                             content: "\f18b";
                           }
.old-icon-zoom-minus:before {
                             content: "\f18c";
                           }
.old-icon-zoom-plus:before {
                             content: "\f18d";
                           }
