@font-face {
  font-family: "mushin-icons";
  src: url("/mushin-icons.eot?#iefix") format("embedded-opentype"),
url("/mushin-icons.woff") format("woff"),
url("/mushin-icons.woff2") format("woff2"),
url("/mushin-icons.ttf") format("truetype"),
url("/mushin-icons.svg#mushin-icons") format("svg");
}

.mu-icon {
}

.mu-icon:before {
  font-family: mushin-icons !important;
  font-style: normal;
  font-weight: normal !important;
  vertical-align: top;
}

.mu-icon-Archive:before {
                             content: "\f101";
                           }
.mu-icon-Calendar:before {
                             content: "\f102";
                           }
.mu-icon-Chat:before {
                             content: "\f103";
                           }
.mu-icon-Check:before {
                             content: "\f104";
                           }
.mu-icon-CheckCircleFill:before {
                             content: "\f105";
                           }
.mu-icon-CheckCircleLine:before {
                             content: "\f106";
                           }
.mu-icon-CheckCircleNormal:before {
                             content: "\f107";
                           }
.mu-icon-Checked:before {
                             content: "\f108";
                           }
.mu-icon-ChromeLogo:before {
                             content: "\f109";
                           }
.mu-icon-Circle:before {
                             content: "\f10a";
                           }
.mu-icon-Close:before {
                             content: "\f10b";
                           }
.mu-icon-CloseCircleFill:before {
                             content: "\f10c";
                           }
.mu-icon-CloseCircleLine:before {
                             content: "\f10d";
                           }
.mu-icon-ClosePanel:before {
                             content: "\f10e";
                           }
.mu-icon-Cloud:before {
                             content: "\f10f";
                           }
.mu-icon-Color:before {
                             content: "\f110";
                           }
.mu-icon-Comments:before {
                             content: "\f111";
                           }
.mu-icon-Computer:before {
                             content: "\f112";
                           }
.mu-icon-Contain:before {
                             content: "\f113";
                           }
.mu-icon-Crop:before {
                             content: "\f114";
                           }
.mu-icon-Delete:before {
                             content: "\f115";
                           }
.mu-icon-Disabled:before {
                             content: "\f116";
                           }
.mu-icon-Dropdown:before {
                             content: "\f117";
                           }
.mu-icon-Edit:before {
                             content: "\f118";
                           }
.mu-icon-Expand:before {
                             content: "\f119";
                           }
.mu-icon-Export:before {
                             content: "\f11a";
                           }
.mu-icon-Features:before {
                             content: "\f11b";
                           }
.mu-icon-Fill:before {
                             content: "\f11c";
                           }
.mu-icon-FolderAdd:before {
                             content: "\f11d";
                           }
.mu-icon-FolderFill:before {
                             content: "\f11e";
                           }
.mu-icon-FolderLine:before {
                             content: "\f11f";
                           }
.mu-icon-HeartFill:before {
                             content: "\f120";
                           }
.mu-icon-HeartLine:before {
                             content: "\f121";
                           }
.mu-icon-Help:before {
                             content: "\f122";
                           }
.mu-icon-Hide:before {
                             content: "\f123";
                           }
.mu-icon-Hover:before {
                             content: "\f124";
                           }
.mu-icon-Idea:before {
                             content: "\f125";
                           }
.mu-icon-Indeterminate:before {
                             content: "\f126";
                           }
.mu-icon-Infinite:before {
                             content: "\f127";
                           }
.mu-icon-InfoLine:before {
                             content: "\f128";
                           }
.mu-icon-Left:before {
                             content: "\f129";
                           }
.mu-icon-Line:before {
                             content: "\f12a";
                           }
.mu-icon-Loading:before {
                             content: "\f12b";
                           }
.mu-icon-LockLine:before {
                             content: "\f12c";
                           }
.mu-icon-LogoMushinFullWhite:before {
                             content: "\f12d";
                           }
.mu-icon-LongArrowLeft:before {
                             content: "\f12e";
                           }
.mu-icon-LongArrowRight:before {
                             content: "\f12f";
                           }
.mu-icon-LongArrowTopRight:before {
                             content: "\f130";
                           }
.mu-icon-MailLine:before {
                             content: "\f131";
                           }
.mu-icon-MapMarker:before {
                             content: "\f132";
                           }
.mu-icon-Mini:before {
                             content: "\f133";
                           }
.mu-icon-Minus:before {
                             content: "\f134";
                           }
.mu-icon-Mobile:before {
                             content: "\f135";
                           }
.mu-icon-Moodboard:before {
                             content: "\f136";
                           }
.mu-icon-MoreOptions:before {
                             content: "\f137";
                           }
.mu-icon-MoveBackward:before {
                             content: "\f138";
                           }
.mu-icon-MoveForward:before {
                             content: "\f139";
                           }
.mu-icon-network-share-1:before {
                             content: "\f13a";
                           }
.mu-icon-Normal:before {
                             content: "\f13b";
                           }
.mu-icon-NotificationFill:before {
                             content: "\f13c";
                           }
.mu-icon-NotificationLine:before {
                             content: "\f13d";
                           }
.mu-icon-OpenPanel:before {
                             content: "\f13e";
                           }
.mu-icon-Pause:before {
                             content: "\f13f";
                           }
.mu-icon-Pdf:before {
                             content: "\f140";
                           }
.mu-icon-Pinterest:before {
                             content: "\f141";
                           }
.mu-icon-Pinti:before {
                             content: "\f142";
                           }
.mu-icon-Play:before {
                             content: "\f143";
                           }
.mu-icon-Plus:before {
                             content: "\f144";
                           }
.mu-icon-PlusCircleFill:before {
                             content: "\f145";
                           }
.mu-icon-Preview:before {
                             content: "\f146";
                           }
.mu-icon-Print:before {
                             content: "\f147";
                           }
.mu-icon-Private:before {
                             content: "\f148";
                           }
.mu-icon-Project:before {
                             content: "\f149";
                           }
.mu-icon-Public:before {
                             content: "\f14a";
                           }
.mu-icon-Publish:before {
                             content: "\f14b";
                           }
.mu-icon-Redo:before {
                             content: "\f14c";
                           }
.mu-icon-Remove:before {
                             content: "\f14d";
                           }
.mu-icon-Right:before {
                             content: "\f14e";
                           }
.mu-icon-RotateLeft:before {
                             content: "\f14f";
                           }
.mu-icon-RotateRight:before {
                             content: "\f150";
                           }
.mu-icon-Save:before {
                             content: "\f151";
                           }
.mu-icon-Search:before {
                             content: "\f152";
                           }
.mu-icon-Send:before {
                             content: "\f153";
                           }
.mu-icon-Settings:before {
                             content: "\f154";
                           }
.mu-icon-Share:before {
                             content: "\f155";
                           }
.mu-icon-Smali:before {
                             content: "\f156";
                           }
.mu-icon-Spinner:before {
                             content: "\f157";
                           }
.mu-icon-Square:before {
                             content: "\f158";
                           }
.mu-icon-Stop:before {
                             content: "\f159";
                           }
.mu-icon-SubFolderFill:before {
                             content: "\f15a";
                           }
.mu-icon-SubFolderLine:before {
                             content: "\f15b";
                           }
.mu-icon-Text:before {
                             content: "\f15c";
                           }
.mu-icon-TextBottom:before {
                             content: "\f15d";
                           }
.mu-icon-TextLeft:before {
                             content: "\f15e";
                           }
.mu-icon-TextLetter:before {
                             content: "\f15f";
                           }
.mu-icon-TextLetterOverlined:before {
                             content: "\f160";
                           }
.mu-icon-TextRight:before {
                             content: "\f161";
                           }
.mu-icon-TextTop:before {
                             content: "\f162";
                           }
.mu-icon-ThumbsDown:before {
                             content: "\f163";
                           }
.mu-icon-ThumbsUp:before {
                             content: "\f164";
                           }
.mu-icon-TradeMark:before {
                             content: "\f165";
                           }
.mu-icon-Undo:before {
                             content: "\f166";
                           }
.mu-icon-Upload:before {
                             content: "\f167";
                           }
.mu-icon-UserLine:before {
                             content: "\f168";
                           }
.mu-icon-Warning:before {
                             content: "\f169";
                           }
.mu-icon-Write:before {
                             content: "\f16a";
                           }
